import { useEffect, useState } from 'react';
import { AuthService as authService } from '../services';
import { userHasSettings } from '../services/userHasSettings';

export function useUserHasSettings(settings: string) {
  const [hasSettings, setHasSettings] = useState(false);

  useEffect(() => {
    async function setUserSettings() {
      const settingsState = await userHasSettings({ authService, settings });
      setHasSettings(settingsState);
    }
    setUserSettings();
  }, [setHasSettings, settings]);

  return hasSettings;
}
