import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  dataTestId?: string;
};

const useStyles = makeStyles({
  root: {
    height: 36,
    width: 215,
    fontFamily: 'Poppins',
    backgroundColor: '#ec674c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#eb826c'
    }
  }
});

export const PrimaryButton: React.FC<Props> = ({ children, onClick, dataTestId }) => {
  const classes = useStyles();

  return (
    <Button data-testid={dataTestId} className={classes.root} variant="contained" size="small" onClick={onClick}>
      {children}
    </Button>
  );
};
