import { Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../theme';

type Props = {
  children: React.ReactNode;
};

export const Subheader: React.FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.subheader}>
      {children}
    </Typography>
  );
};
