import React from 'react';
import Typography from '@material-ui/core/Typography';

type Props = {
  children: React.ReactNode;
};

const Title: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {children}
    </Typography>
  );
};

export default Title;
