import { createTheme, makeStyles, responsiveFontSizes } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  toolbar: {
    paddingRight: 24
  },
  toolbarIcon: {
    margin: 6,
    alignSelf: 'flex-end'
  },
  containerItem: {
    margin: 12
  },
  appBar: {
    zIndex: 1
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  primaryButton: {
    height: 36,
    width: 215,
    fontFamily: 'Poppins',
    backgroundColor: '#ec674c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#eb826c'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  logoutButton: {
    color: 'white'
  },
  whiteIcon: {
    color: 'white'
  },
  subheader: {
    fontFamily: 'Poppins',
    margin: 40
  }
}));

let muiTheme = createTheme({
  typography: {
    fontFamily: ['Roboto Slab, Poppins'].join(',')
  },
  palette: {
    primary: {
      main: '#21366B',
      light: '#3a61c2'
    },
    secondary: {
      main: '#26E6B4'
    },
    error: {
      main: '#a94466'
    }
  }
});

muiTheme = responsiveFontSizes(muiTheme);
export const theme = muiTheme;
