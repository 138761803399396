import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Layout from '../../App/containers/Layout';
import { AuthService } from '../../Auth/services';
import { httpReportsRepository } from '../../Reports/infrastructure/HttpReportsRepository';
import { LoadingOverlay } from '../../shared/LoadingOverlay';
import Monitoring from '../domain/Monitoring';
import { monitoringsRepository } from '../infrastructure/MonitoringsRepository';
import AnalyzeMonitoring from './AnalyzeMonitoring';
import { MonitoringsList } from './MonitoringsList';
type Props = {
  platform: string;
  path: string;
};

export const Monitorings: React.FunctionComponent<Props> = ({ platform, path }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [monitorings, setMonitorings] = useState<Monitoring[]>([]);
  const [analyzeDialogOpened, setAnalyzeDialogOpened] = useState(false);
  const [monitoringsLoading, setMonitoringsLoading] = useState(true);
  const [defaultTitle, setDefaultTitle] = useState('');
  const [groupId, setGroupId] = useState('');
  const [defaultStart, setDefaultStart] = useState('');
  const [defaultEnd, setDefaultEnd] = useState('');
  const [monitoringId, setMonitoringId] = useState('');

  const handleOpenAnalyze = (id: string, name: string, groupId: string, start: string, end: string) => {
    setMonitoringId(id);
    setGroupId(groupId);
    setAnalyzeDialogOpened(true);
    setDefaultTitle(`${name} - ${new Date().toLocaleString()}`);
    setDefaultStart(start);
    setDefaultEnd(end);
  };

  const handleCloseAnalyzeDialog = () => {
    setAnalyzeDialogOpened(false);
  };

  const handleAnalyze = async (title: string, start: string, end: string) => {
    const formatedStartDate = format(parseISO(start), 'MM/dd/yyyy');
    const formatedEndDate = format(parseISO(end), 'MM/dd/yyyy');

    try {
      const token = await AuthService.getUserToken();
      if (!token) {
        throw Error('Not valid token');
      }
      await httpReportsRepository.create(
        token,
        monitoringId,
        groupId,
        title,
        formatedStartDate,
        formatedEndDate,
        platform
      );
      enqueueSnackbar(`Successully requested audience analysis for monitoring ${title}`, {
        variant: 'success'
      });
      setAnalyzeDialogOpened(false);
      return true;
    } catch (error) {
      console.error('Error analyzing', error);
      enqueueSnackbar(`Error requesting audience analysis for monitoring ${title}`, {
        variant: 'error'
      });
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await AuthService.getUserToken();
        if (!token) {
          throw Error('Not valid token');
        }
        const monitorings = await monitoringsRepository.retrieve(platform, token);
        setMonitorings(monitorings);
      } catch (error) {
        enqueueSnackbar(
          `Error retrieving your monitorings, ensure your account is properly configured and that you are connected to Internet.`,
          {
            variant: 'error'
          }
        );
      }
      setMonitoringsLoading(false);
    };
    fetchData();
  }, [enqueueSnackbar, platform]);

  return (
    <Layout>
      <LoadingOverlay isLoading={monitoringsLoading} />
      {!monitoringsLoading && <MonitoringsList monitorings={monitorings} handleOpenAnalyze={handleOpenAnalyze} />}
      <AnalyzeMonitoring
        open={analyzeDialogOpened}
        handleClose={handleCloseAnalyzeDialog}
        handleAnalyze={handleAnalyze}
        title={defaultTitle}
        start={defaultStart}
        end={defaultEnd}
        sending={false}
      />
    </Layout>
  );
};
