import React from 'react';
import { TextField, withStyles } from '@material-ui/core';
import { theme } from '../../theme';

interface SearchProps {
  onChange: any;
}

const SearchTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  }
})(TextField);

export const SearchBar: React.FC<SearchProps> = ({ onChange }) => (
  <SearchTextField
    fullWidth
    variant="outlined"
    label="Search"
    margin="dense"
    onChange={event => onChange(event.target.value)}
  />
);
