import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { format, sub } from 'date-fns';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

type Props = {
  open: boolean;
  title: string;
  sending: boolean;
  start: string;
  end: string;
  handleClose: () => void;
  handleAnalyze: (title: string, start: string, end: string) => Promise<boolean>;
};

export const AnalyzeMonitoring: React.FunctionComponent<Props> = ({
  open,
  title,
  sending,
  handleClose,
  handleAnalyze
}) => {
  const classes = useStyles();
  const endDate = format(new Date(), 'yyyy-MM-dd');
  const startDate = format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd');
  const [isOpen, setIsOpen] = useState(open);
  const [monitoringTitle, setMonitoringTitle] = useState(title);
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const [isSending, setIsSending] = useState(false);

  React.useEffect(() => {
    setIsOpen(open);
    setMonitoringTitle(title);
    setIsSending(sending);
  }, [open, title, sending]);

  const handleAnalyzeClick = async () => {
    setIsSending(true);
    const handled = await handleAnalyze(monitoringTitle, start, end);
    if (!handled) {
      setIsSending(false);
    }
  };

  const handleEmailChanged = (event: any) => {
    setMonitoringTitle(event.target.value);
  };

  const handleStartChanged = (event: any) => {
    setStart(event.target.value);
  };

  const handleEndChanged = (event: any) => {
    setEnd(event.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Analyze Monitoring Audience</DialogTitle>
      <DialogContent>
        <DialogContentText>Create an Audiense report based on the monitoring's authors.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Report Title"
          type="text"
          value={title}
          fullWidth
          onChange={handleEmailChanged}
        />
        <TextField
          margin="dense"
          id="start"
          label="Start Date"
          InputLabelProps={{
            shrink: true
          }}
          type="date"
          value={start}
          fullWidth
          onChange={handleStartChanged}
        />
        <TextField
          margin="dense"
          id="end"
          label="End Date"
          InputLabelProps={{
            shrink: true
          }}
          type="date"
          value={end}
          fullWidth
          onChange={handleEndChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <div className={classes.wrapper}>
          <Button onClick={handleAnalyzeClick} color="primary" variant="contained" disabled={sending}>
            Analyze
          </Button>
          {isSending && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AnalyzeMonitoring;
