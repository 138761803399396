import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from '../../theme';
import paths from '../paths';

const NotFound = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container alignContent="center">
      <Grid container item justifyContent="center" className={classes.containerItem} xs={12}>
        <Typography variant="h1">404</Typography>
      </Grid>
      <Grid container item justifyContent="center" className={classes.containerItem} xs={12}>
        <Typography variant="h2">Page not found</Typography>
      </Grid>
      <Grid container item justifyContent="center" className={classes.containerItem} xs={12}>
        <Typography variant="subtitle1">
          The page you are looking for does not exist or another error occured. Go to <Link to={paths.HOME}> Home</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
