import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Copyright from '../components/Copyright';
import { AuthService } from '../../Auth/services';
import { useStyles } from '../../theme';
import { LogoutButton } from './LogoutButton';
import { Logotype } from './Logotype';

type Props = {
  children: any;
};

const Layout: React.FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();

  const handleLogout = async () => {
    await AuthService.logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Logotype />
        <LogoutButton handleLogout={handleLogout} />
        <Container maxWidth={'lg'} className={classes.container}>
          {children}
        </Container>
        <Copyright />
      </main>
    </div>
  );
};

export default Layout;
