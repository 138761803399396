import { Button, Chip, Link, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import LoadingTableRow from '../../App/components/LoadingTableRow';
import Title from '../../shared/Title';
import Report from '../domain/Report';
import { formatDistance } from 'date-fns';
type Props = {
  reports: Report[];
};

export const ReportsList: React.FunctionComponent<Props> = ({ reports }) => {
  const capitalizeFirstLetter = (stringToProcess: string) => {
    return stringToProcess.charAt(0).toUpperCase() + stringToProcess.slice(1);
  };

  const getReportStatusChip = (status: string) => {
    const statusLabel = capitalizeFirstLetter(status);
    if (status === 'pending') return <Chip color="default" label={statusLabel}></Chip>;
    if (status === 'finished') return <Chip color="secondary" label={statusLabel}></Chip>;
    if (status === 'failed')
      return <Chip style={{ backgroundColor: '#C00000', color: 'white' }} label={statusLabel}></Chip>;
  };

  return (
    <>
      <Title>Reports</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Report</TableCell>
            <TableCell align="center">Created</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        {!reports && <LoadingTableRow colSpan={3} />}
        {reports && !reports.length && (
          <TableRow>
            <TableCell colSpan={3} align={'center'}>
              There are no reports.
            </TableCell>
          </TableRow>
        )}
        <TableBody>
          {reports &&
            reports.map(report => (
              <TableRow key={report.reportId}>
                <TableCell>
                  <Tooltip title="See report details" placement="bottom">
                    <Link underline="always" href={report.link}>
                      {report.title}
                    </Link>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  {formatDistance(new Date(report.dateAdded), new Date(), { addSuffix: true })}
                </TableCell>

                <TableCell align="center">{getReportStatusChip(report.status)}</TableCell>
                <TableCell align="right">
                  {report.link && (
                    <Button variant="contained" color="primary" size="small" href={report.link} target={'blank'}>
                      View
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};
