import React from 'react';
import { Fab } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

type Props = {
  handleLogout: () => void;
};

export const LogoutButton: React.FunctionComponent<Props> = ({ handleLogout }) => (
  <Fab
    style={{
      position: 'absolute',
      top: 30,
      right: 40,
      backgroundColor: '#F44336',
      color: 'white'
    }}
    onClick={handleLogout}
    variant="extended"
  >
    <ExitToAppIcon style={{ marginRight: 10 }} />
    Log out
  </Fab>
);
