import React from 'react';
import { AuthService } from '../services';
import LoginForm from './LoginForm';

export const Login: React.FunctionComponent = () => {
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleLoginUser = async (inputEmail: string, inputPassword: string) => {
    setFetching(true);
    const email = inputEmail.trim();
    try {
      await AuthService.login(email, inputPassword);
    } catch (error) {
      setFetching(false);
      setError(error.message);
    }
  };

  return <LoginForm error={error} fetching={fetching} onSubmit={handleLoginUser} />;
};

export default Login;
