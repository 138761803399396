import { QueriesRepository } from '../domain/QueriesRepository';
import { Query } from '../domain/Query';
import axios from 'axios';

class BrandwatchQueriesRepository implements QueriesRepository {
  async retrieve(token: string, projectId: string): Promise<Query[]> {
    const BACKEND_API_URL = process.env.REACT_APP_API_PATH || 'http://localhost:3000/api/';
    const response = await axios.get<Query[]>(`${BACKEND_API_URL}brandwatch/queries/${projectId}/${token}`);
    return response.data;
  }
}
const brandwatchQueriesRepository = new BrandwatchQueriesRepository();
export { brandwatchQueriesRepository };
