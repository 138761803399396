import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Integrations } from './Integrations';
import Layout from '../App/containers/Layout';

export type Props = RouteComponentProps & {
  section: string;
  history: any;
};

const Home: React.FunctionComponent<Props> = ({ section, history }) => {
  const handleSectionChanged = (section: string) => {
    history.push(section);
  };

  return (
    <Layout>
      <Integrations handleSectionChanged={handleSectionChanged} />
    </Layout>
  );
};

export default withRouter(Home);
