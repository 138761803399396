import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import User, { UserId } from '../domain/User';
import { UsersRepository } from '../domain/UsersRepository';
import firebaseConfig from './firebaseConfig.json';

export class FirebaseUsersRepository implements UsersRepository {
  private database: any;

  constructor() {
    const app = initializeApp(firebaseConfig);

    this.database = getFirestore(app);
  }

  async findById(id: UserId): Promise<User> {
    const userDocumentReference = doc(this.database, 'users', id);
    const userDocument = await getDoc(userDocumentReference);
    const userDocumentData = userDocument.data();

    if (!userDocumentData) {
      throw new Error(`User with id ${id} not found`);
    }
    return this.mapDocumentToUser(userDocumentData, id);
  }

  async getUserPlatformToken(userId: string, platform: string): Promise<string> {
    const userDocumentReference = doc(this.database, 'users', userId);
    const userDocument = await getDoc(userDocumentReference);
    const userDocumentData = userDocument.data();

    if (!userDocumentData) {
      throw new Error(`User with id ${userId} not found`);
    }

    if (platform === 'brandwatch') {
      return userDocumentData.brandwatch_settings.token;
    }
    return '';
  }

  private mapDocumentToUser(userDocument: any, id: UserId): User {
    const settings = [];
    userDocument.hubspot_settings && settings.push('hubspot');
    userDocument.talkwalker_settings && settings.push('talkwalker');
    userDocument.brandwatch_settings && settings.push('brandwatch');
    userDocument.netbase_settings && settings.push('netbase');
    return {
      id,
      settings
    };
  }
}
