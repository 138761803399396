import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import AccessToken from '../domain/AccessToken';
import { AuthClient } from '../domain/AuthClient';
import { Email, Password, UserId } from '../domain/User';
import firebaseConfig from './firebaseConfig.json';

export class FirebaseAuthClient implements AuthClient {
  private readonly auth: any;
  private authStateChanged: any;

  constructor() {
    const app = initializeApp(firebaseConfig);
    this.auth = getAuth(app);
    onAuthStateChanged(this.auth, (user: any) => {
      if (this.authStateChanged) {
        this.authStateChanged(user ? user.uid : null);
      }
    });
  }

  setOnAuthStateChange(callback: Function) {
    this.authStateChanged = callback;
  }

  getCurrentUser(): UserId | null {
    const user = this.auth.currentUser;
    return user ? user.uid : null;
  }

  async login(email: Email, password: Password): Promise<void> {
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  async logout(): Promise<void> {
    await this.auth.signOut();
  }

  async getUserToken(): Promise<AccessToken | null> {
    const user = this.auth.currentUser;
    if (!user) {
      return null;
    }
    return await user.getIdToken();
  }
}
