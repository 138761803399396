import GroupId from '../../Monitorings/domain/GroupId';
import MonitoringId from '../../Monitorings/domain/MonitoringId';
import Report from '../domain/Report';
import ReportId from '../domain/ReportId';
import { ReportsRepository } from '../domain/ReportsRepository';
import { Dispatcher } from '../../Core/Dispatcher';

class HttpReportsRepository implements ReportsRepository {
  private readonly dispatcher: Dispatcher;
  constructor() {
    this.dispatcher = new Dispatcher();
  }

  async retrieve(token: string): Promise<Report[]> {
    const reportsResponse = await this.dispatcher.dispatchQuery({
      name: 'reports',
      token
    });
    return reportsResponse.data.reports;
  }

  async create(
    token: string,
    monitoringId: MonitoringId,
    groupId: GroupId,
    title: string,
    start: string,
    end: string,
    platform: string
  ): Promise<void> {
    const reportId = ReportId.create().value;
    await this.dispatcher.dispatchCommand({
      name: `create-${platform}-report`,
      token,
      data: { reportId, monitoringId, groupId, title, start, end }
    });
    return;
  }

  async export(token: string, monitoringId: MonitoringId): Promise<void> {
    await this.dispatcher.dispatchCommand({
      name: 'create-report-from-hubspot',
      token,
      data: { monitoringId }
    });
    return;
  }
}

const httpReportsRepository = new HttpReportsRepository();

export { httpReportsRepository };
