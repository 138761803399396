import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../App/containers/Layout';
import { AuthService } from '../../Auth/services';
import { httpReportsRepository } from '../../Reports/infrastructure/HttpReportsRepository';
import { LoadingOverlay } from '../../shared/LoadingOverlay';
import { Query } from '../domain/Query';
import { brandwatchQueriesRepository } from '../infrastructure/BrandwatchQueriesRepository';
import AnalyzeQuery from './AnalyzeQuery';
import { BrandwatchQueriesList } from './BrandwatchQueriesList';

export const BrandwatchQueries: React.FunctionComponent = () => {
  const { projectId }: { projectId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [queries, setBrandwatchQueries] = useState<Query[]>([]);
  const [analyzeDialogOpened, setAnalyzeDialogOpened] = useState(false);
  const [queriesLoading, setBrandwatchQueriesLoading] = useState(true);
  const [defaultTitle, setDefaultTitle] = useState('');
  const [groupId, setProjectId] = useState(projectId);
  const [defaultStart, setDefaultStart] = useState('');
  const [defaultEnd, setDefaultEnd] = useState('');
  const [queryId, setQueryId] = useState('');

  const handleOpenAnalyze = (id: string, name: string, groupId: string, start: string, end: string) => {
    setQueryId(id);
    setProjectId(groupId);
    setAnalyzeDialogOpened(true);
    setDefaultTitle(`${name} - ${new Date().toLocaleString()}`);
    setDefaultStart(start);
    setDefaultEnd(end);
  };

  const handleCloseAnalyzeDialog = () => {
    setAnalyzeDialogOpened(false);
  };

  const handleAnalyze = async (title: string, start: string, end: string) => {
    const formatedStartDate = format(parseISO(start), 'MM/dd/yyyy');
    const formatedEndDate = format(parseISO(end), 'MM/dd/yyyy');

    try {
      const token = await AuthService.getUserToken();
      if (!token) {
        throw Error('Not valid token');
      }
      await httpReportsRepository.create(
        token,
        queryId,
        groupId,
        title,
        formatedStartDate,
        formatedEndDate,
        'brandwatch'
      );
      enqueueSnackbar(`Successully requested audience analysis for monitoring ${title}`, {
        variant: 'success'
      });
      setAnalyzeDialogOpened(false);
      return true;
    } catch (error) {
      console.error('Error analyzing', error);
      enqueueSnackbar(`Error requesting audience analysis for monitoring ${title}`, {
        variant: 'error'
      });
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandwatchToken = await AuthService.getUserPlatformToken('brandwatch');

        if (!brandwatchToken) {
          throw Error('Token not found');
        }

        const queries = await brandwatchQueriesRepository.retrieve(brandwatchToken, projectId);
        setBrandwatchQueries(queries);
      } catch (error) {
        enqueueSnackbar(
          `Error retrieving your queries, ensure your account is properly configured and that you are connected to Internet.`,
          {
            variant: 'error'
          }
        );
      }
      setBrandwatchQueriesLoading(false);
    };
    fetchData();
  }, [enqueueSnackbar, projectId]);

  return (
    <Layout>
      <LoadingOverlay isLoading={queriesLoading} />
      {!queriesLoading && <BrandwatchQueriesList queries={queries} handleOpenAnalyze={handleOpenAnalyze} />}
      <AnalyzeQuery
        open={analyzeDialogOpened}
        handleClose={handleCloseAnalyzeDialog}
        handleAnalyze={handleAnalyze}
        title={defaultTitle}
        start={defaultStart}
        end={defaultEnd}
        sending={false}
      />
    </Layout>
  );
};
