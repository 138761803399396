import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { AuthService } from '../../../Auth/services';
import { httpReportsRepository } from '../../infrastructure/HttpReportsRepository';
import { HubspotForm } from './HubspotForm';

type Props = {
  onClose: () => void;
  open: boolean;
};

export const HubspotFormContainer: React.FunctionComponent<Props> = ({ onClose, open }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const requestHubspotExport = useCallback(
    async monitoringId => {
      setIsLoading(true);
      try {
        const token = await AuthService.getUserToken();
        if (!token) throw new Error('Error with user credentials');

        await httpReportsRepository.export(token, monitoringId);
        enqueueSnackbar('Export submitted succesfully.', {
          variant: 'success'
        });
      } catch (error) {
        enqueueSnackbar('Error', {
          variant: 'error'
        });
      }
      setIsLoading(false);
    },
    [enqueueSnackbar]
  );

  return <HubspotForm isLoading={isLoading} open={open} onClose={onClose} handleCreateReport={requestHubspotExport} />;
};
