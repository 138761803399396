import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import User from '../../Auth/domain/User';
import Login from '../../Auth/presentation/Login';
import { AuthService } from '../../Auth/services';
import BrandwatchProjects from '../../Brandwatch/components/BrandwatchProjects';
import { BrandwatchQueries } from '../../Brandwatch/components/BrandwatchQueries';
import Home from '../../Homepage/Home';
import { Monitorings } from '../../Monitorings/presentation/Container';
import Reports from '../../Reports/presentation/Container';
import { theme } from '../../theme';
import NotFound from '../components/NotFound';
import paths from '../paths';

export default function App() {
  const [bootingApplication, setBootingApplication] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    AuthService.onAuthStateChanged((user: User) => {
      setUser(user);
      setBootingApplication(false);
    });
  }, []);

  if (bootingApplication) {
    return <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Switch>
            {!user ? (
              <Route exact path={paths.HOME} component={Login} />
            ) : (
              <>
                <Route exact path={paths.HOME} component={Home} />
                <Route
                  exact
                  path={paths.TALKWALKER_MONITORINGS}
                  render={() => <Monitorings platform="talkwalker" path={paths.TALKWALKER_MONITORINGS} />}
                />
                <Route
                  exact
                  path={paths.BRANDWATCH_MONITORINGS}
                  render={() => <BrandwatchProjects path={paths.BRANDWATCH_MONITORINGS} />}
                />
                <Route exact path={`${paths.BRANDWATCH_QUERIES}/:projectId`} component={BrandwatchQueries} />
                <Route
                  exact
                  path={paths.NETBASE_MONITORINGS}
                  render={() => <Monitorings platform="netbase" path={paths.NETBASE_MONITORINGS} />}
                />
                <Route exact path={paths.REPORTS} component={Reports} />
              </>
            )}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
