import { Button, Container, Grid, Paper, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { SearchBar } from '../../Monitorings/presentation/SearchBar';
import Title from '../../shared/Title';
import { useStyles } from '../../theme';
import { Project } from '../domain/Project';

type Props = {
  projects: Project[];
  onViewQueries: (projectId: number) => void;
};
export const BrandwatchProjectsList: React.FunctionComponent<Props> = ({ projects, onViewQueries }) => {
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const classes = useStyles();

  const filterProjects = (projects: Project[]): Project[] => {
    let newProjects = projects.filter((project: Project) => {
      return project.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    });
    return newProjects;
  };

  const filteredProjects = projects && filterProjects(projects);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper className={classes.paper}>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Title>Projects</Title>
          </Grid>
          <Grid container item md={8} xs={12} alignItems="center">
            <SearchBar onChange={(value: string) => setSearchValue(value)} />
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredProjects.length ? (
              <TableRow>
                <TableCell colSpan={5} align={'center'}>
                  There are no projects.
                </TableCell>
              </TableRow>
            ) : (
              filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(project => (
                <TableRow key={project.id}>
                  <TableCell width="50%">{project.name}</TableCell>
                  <TableCell>{project.description}</TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => {
                        onViewQueries(project.id);
                      }}
                    >
                      View Queries
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {filteredProjects && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            rowsPerPage={rowsPerPage}
            component="div"
            count={filteredProjects.length}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Container>
    </Paper>
  );
};
