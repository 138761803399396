import AccessToken from '../domain/AccessToken';
import { AuthClient } from '../domain/AuthClient';
import User, { Email, Password } from '../domain/User';
import { UsersRepository } from '../domain/UsersRepository';

class AuthService {
  private onAuthChangeCallback?: Function;
  constructor(private client: AuthClient, private usersRepository: UsersRepository) {}

  async login(email: Email, password: Password): Promise<void> {
    try {
      await this.client.login(email, password);
      const user = await this.getCurrentUser();
      if (!user) {
        throw new Error('Empty user');
      }
      this.onLogin(user);
    } catch (error) {
      throw new Error('Invalid email and password combination');
    }
  }

  async logout(): Promise<void> {
    await this.client.logout();
    this.onLogout();
  }

  onAuthStateChanged(callback: Function): void {
    this.client.setOnAuthStateChange(callback);
  }

  async getCurrentUser(): Promise<User | null> {
    const userId = this.client.getCurrentUser();
    if (!userId) return null;
    return await this.usersRepository.findById(userId);
  }

  async getUserToken(): Promise<AccessToken | null> {
    return await this.client.getUserToken();
  }

  async getUserPlatformToken(platform: string): Promise<AccessToken | null> {
    const userId = this.client.getCurrentUser();
    if (!userId) return null;
    return await this.usersRepository.getUserPlatformToken(userId, platform);
  }

  private onLogin(user: User): void {
    if (this.onAuthChangeCallback) {
      this.onAuthChangeCallback(user);
    }
  }

  private onLogout(): void {
    if (this.onAuthChangeCallback) {
      this.onAuthChangeCallback(null);
    }
  }
}

export default AuthService;
