import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useUserHasSettings } from '../Auth/hooks/useUserHasSettings';
import paths from '../App/paths';
import { PrimaryCard } from './PrimaryCard';
// import BrandwatchLogo from '../assets/img/Brandwatch_Logotype.png';
import TalkwalkerLogo from '../assets/img/Talkwalker_Logotype.png';
import HubspotLogo from '../assets/img/Hubspot_Logotype.png';
import NetbaseLogo from '../assets/img/Netbase_Logotype.jpg';
import { Subheader } from '../shared/Subheader';
import { useStyles } from '../theme';
import { HubspotFormContainer } from '../Reports/presentation/Hubspot/HubspotFormContainer';
import { LoadingOverlay } from '../shared/LoadingOverlay';
import { PrimaryButton } from '../shared/PrimaryButton';

type Props = {
  handleSectionChanged: (section: string) => void;
};

export const Integrations: React.FunctionComponent<Props> = ({ handleSectionChanged }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hubspotFormIsOpen, setOpenHubspotForm] = useState<boolean>(false);
  const classes = useStyles();

  const handleOpenHubspotForm = () => {
    setOpenHubspotForm(true);
  };

  const handleCloseHubspotForm = () => {
    setOpenHubspotForm(false);
  };

  const handleLoading = () => {
    setIsLoading(false);
  };

  const integrations = [
    {
      platform: 'Hubspot',
      available: useUserHasSettings('hubspot'),
      logo: HubspotLogo,
      description: 'Export your Hubspot lists and create Audiense Reports based on them.',
      color: '#FF5722',
      handleClick: handleOpenHubspotForm
    },
    // {
    //   platform: 'Brandwatch',
    //   available: useUserHasSettings('brandwatch'),
    //   logo: BrandwatchLogo,
    //   description: 'Create Audiense Reports based on Brandwatch projects.',
    //   color: '#673AB7',
    //   handleClick: () => handleSectionChanged(paths.BRANDWATCH_MONITORINGS)
    // },
    {
      platform: 'Talkwalker',
      available: useUserHasSettings('talkwalker'),
      logo: TalkwalkerLogo,
      description: 'Export any of your Talkwalker reports to Audiense.',
      color: '#3F51B5',
      handleClick: () => handleSectionChanged(paths.TALKWALKER_MONITORINGS)
    },
    {
      platform: 'Netbase',
      available: useUserHasSettings('netbase'),
      logo: NetbaseLogo,
      description: 'Analyze Netbase projects and create Audiense Reports based on them.',
      color: '#F21E3B',
      handleClick: () => handleSectionChanged(paths.NETBASE_MONITORINGS)
    }
  ];

  return (
    <Grid justifyContent="center" container className={classes.containerItem}>
      <LoadingOverlay isLoading={isLoading} />
      <Subheader>Your integrations</Subheader>
      <Grid container item justifyContent="center" spacing={4}>
        {integrations.map(
          integration =>
            integration.available && (
              <Grid container justifyContent="center" item lg={4} md={6} xs={12}>
                <PrimaryCard
                  loading={handleLoading}
                  image={integration.logo}
                  name={integration.platform}
                  description={integration.description}
                  color={integration.color}
                >
                  <PrimaryButton onClick={integration.handleClick}>Create</PrimaryButton>
                </PrimaryCard>
              </Grid>
            )
        )}
      </Grid>
      <HubspotFormContainer open={hubspotFormIsOpen} onClose={handleCloseHubspotForm} />
    </Grid>
  );
};
