import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Layout from '../../App/containers/Layout';
import { AuthService } from '../../Auth/services';
import { LoadingOverlay } from '../../shared/LoadingOverlay';
import Report from '../domain/Report';
import { httpReportsRepository } from '../infrastructure/HttpReportsRepository';
import { ReportsList } from './ReportsList';

export default function Reports() {
  const [reportsLoading, setReportsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [reports, setReports] = useState<Report[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await AuthService.getUserToken();
        if (token) {
          const reports = await httpReportsRepository.retrieve(token);
          setReports(reports);
        }
      } catch (error) {
        enqueueSnackbar(
          `Error retrieving your reports, ensure your account is properly configured and that you are connected to Internet.`,
          {
            variant: 'error'
          }
        );
      }
      setReportsLoading(false);
    };
    fetchData();
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <LoadingOverlay isLoading={reportsLoading} />
      {!reportsLoading && <ReportsList reports={reports} />}
    </Layout>
  );
}
