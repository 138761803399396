import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    border: 0,
    borderTopWidth: 4,
    borderStyle: 'solid',
    borderColor: ({ color }: { color: string }) => color
  },
  mediaContainer: {
    margin: 20,
    width: 305,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  media: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  name: { marginBottom: 10, fontFamily: 'Poppins' },
  actions: { marginTop: 60, marginBottom: 30, justifyContent: 'center' }
});

export interface Props {
  image: string;
  name?: string;
  description: string;
  children: React.ReactNode;
  color: string;
  loading: () => void;
}

export const PrimaryCard: React.FunctionComponent<Props> = ({ image, name, description, children, color, loading }) => {
  const classes = useStyles({ color });
  const [raised, setRaised] = useState(false);

  useEffect(() => loading());

  return (
    <Card
      onMouseEnter={() => setRaised(true)}
      onMouseLeave={() => setRaised(false)}
      raised={raised}
      className={classes.card}
    >
      <CardActionArea disabled>
        <div className={classes.mediaContainer}>
          <img alt="primary-card-media" data-testid="card-image" className={classes.media} src={image} />
        </div>
        <CardContent>
          <Typography className={classes.name} align="center" variant="h5">
            {name}
          </Typography>
          <Typography align="center" variant="body2">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions data-testid="children-actions" className={classes.actions}>
        {children}
      </CardActions>
    </Card>
  );
};
