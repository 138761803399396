import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';

type Props = {
  isLoading: boolean;
};
export const LoadingOverlay: React.FC<Props> = ({ isLoading }) => {
  return (
    <Backdrop style={{ zIndex: 1 }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
