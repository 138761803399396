import React from 'react';
import PuvlicLogo from '../../assets/img/Puvlic_Logotype.png';

export const Logotype = () => (
  <img
    style={{ position: 'absolute', top: 20, left: 20, height: 'auto', width: 200 }}
    src={PuvlicLogo}
    alt="puvlic-logotype"
  ></img>
);
