import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  colSpan: number;
};

const LoadingTableRow: React.FunctionComponent<Props> = ({ colSpan }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        <CircularProgress size={24} />
      </TableCell>
    </TableRow>
  );
};

export default LoadingTableRow;
