import { Project } from '../domain/Project';
import { ProjectsRepository } from '../domain/ProjectsRepository';
import axios from 'axios';

class BrandwatchProjectsRepository implements ProjectsRepository {
  async retrieve(token: string): Promise<Project[]> {
    const BACKEND_API_URL = process.env.REACT_APP_API_PATH || 'http://localhost:3000/api/';
    const response = await axios.get<Project[]>(`${BACKEND_API_URL}brandwatch/projects/${token}`);
    return response.data;
  }
}

const brandwatchProjectsRepository = new BrandwatchProjectsRepository();

export { brandwatchProjectsRepository };
