import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Layout from '../../App/containers/Layout';
import { AuthService } from '../../Auth/services';
import { LoadingOverlay } from '../../shared/LoadingOverlay';
import { Project } from '../domain/Project';
import { brandwatchProjectsRepository } from '../infrastructure/BrandwatchProjectsRepository';
import { BrandwatchProjectsList } from './BrandwatchProjectsList';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import paths from '../../App/paths';

type Props = RouteComponentProps & {
  path: string;
  history: any;
};

const BrandwatchProjects: React.FunctionComponent<Props> = ({ path, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [projects, setBrandwatchProjects] = useState<Project[]>([]);
  const [projectsLoading, setBrandwatchProjectsLoading] = useState(true);

  const onViewQueries = (projectId: number) => {
    history.push(`${paths.BRANDWATCH_QUERIES}/${projectId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandwatchToken = await AuthService.getUserPlatformToken('brandwatch');

        if (!brandwatchToken) {
          throw Error('Token not found');
        }

        const projects = await brandwatchProjectsRepository.retrieve(brandwatchToken);
        setBrandwatchProjects(projects);
      } catch (error) {
        enqueueSnackbar(
          `Error retrieving your projects, ensure your account is properly configured and that you are connected to Internet.`,
          {
            variant: 'error'
          }
        );
      }
      setBrandwatchProjectsLoading(false);
    };
    fetchData();
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <LoadingOverlay isLoading={projectsLoading} />
      {!projectsLoading && <BrandwatchProjectsList projects={projects} onViewQueries={onViewQueries} />}
    </Layout>
  );
};

export default withRouter(BrandwatchProjects);
