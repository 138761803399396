import { Button, Container, Grid, Paper, TablePagination, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Title from '../../shared/Title';
import { useStyles } from '../../theme';
import Monitoring from '../domain/Monitoring';
import { ProjectFilter } from './ProjectFilter';
import { SearchBar } from './SearchBar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type Props = {
  monitorings: Monitoring[];
  handleOpenAnalyze: (id: string, name: string, groupId: string, start: string, end: string) => void;
};
export const MonitoringsList: React.FunctionComponent<Props> = ({ monitorings, handleOpenAnalyze }) => {
  function createHandleAnalyze(id: string, name: string, groupId: string, start: string, end: string) {
    handleOpenAnalyze(id, name, groupId, start, end);
  }

  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [projects, setProjects] = React.useState<string[]>([]);

  const [checkedProjects, setCheckedProjects] = React.useState<string[]>([]);

  const handleToggleProjects = (value: string) => {
    if (value === 'All' && checkedProjects.length === projects.length) {
      setCheckedProjects([]);
      return;
    }

    if (value === 'All') {
      setCheckedProjects(projects);
      return;
    }
    const projectIndex = checkedProjects.indexOf(value);
    const newCheckedProjects = [...checkedProjects];

    if (projectIndex === -1) {
      newCheckedProjects.push(value);
    } else {
      newCheckedProjects.splice(projectIndex, 1);
    }

    setCheckedProjects(newCheckedProjects);
  };

  const classes = useStyles();

  const filterMonitorings = (monitorings: Monitoring[]): Monitoring[] => {
    let projectMonitorings = monitorings.filter(monitoring => checkedProjects.includes(monitoring.description));
    let newMonitorings = projectMonitorings.filter((monitoring: Monitoring) => {
      return monitoring.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    });
    const currentPageInterval = rowsPerPage * page + rowsPerPage;
    if (newMonitorings.length < currentPageInterval && page !== 0) {
      setPage(0);
    }
    return newMonitorings;
  };

  const filteredMonitorings = monitorings && filterMonitorings(monitorings);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const allProjects = monitorings && monitorings.map(monitoring => monitoring.description);
    const projects = allProjects && allProjects.filter((item, i, ar) => ar.indexOf(item) === i);
    if (projects) {
      setProjects(projects);
      setCheckedProjects(projects);
    }
  }, [monitorings]);

  return (
    <Paper className={classes.paper}>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Title>Monitorings</Title>
          </Grid>
          <Grid container item md={8} xs={12} alignItems="center">
            <Grid item md={4} xs={12}>
              <ProjectFilter
                checkedProjects={checkedProjects}
                handleToggleProjects={handleToggleProjects}
                projects={projects}
              />
            </Grid>
            <Grid item md={8} xs={12} style={{ padding: 12 }}>
              <SearchBar onChange={(value: string) => setSearchValue(value)} />
            </Grid>
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              {filteredMonitorings.length !== 0 && filteredMonitorings[0].type && <TableCell>Type</TableCell>}
              {filteredMonitorings.length !== 0 && filteredMonitorings[0].enabled && (
                <TableCell align="center">Enabled</TableCell>
              )}
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredMonitorings.length ? (
              <TableRow>
                <TableCell colSpan={5} align={'center'}>
                  There are no monitorings.
                </TableCell>
              </TableRow>
            ) : (
              filteredMonitorings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(monitoring => (
                <TableRow key={monitoring.id}>
                  <TableCell style={{ width: '50%' }}>
                    <Grid container alignItems="center">
                      {monitoring.name}
                      {monitoring.enabled && (
                        <Tooltip title="Enabled" aria-label="enabled" placement="right">
                          <CheckCircleIcon style={{ marginLeft: 4 }} color="secondary" />
                        </Tooltip>
                      )}
                    </Grid>
                  </TableCell>
                  {monitoring.description && <TableCell>{monitoring.description}</TableCell>}
                  {monitoring.type && <TableCell>{monitoring.type}</TableCell>}
                  <TableCell align="right">
                    <Button
                      onClick={() =>
                        createHandleAnalyze(
                          monitoring.id,
                          monitoring.name,
                          monitoring.groupId,
                          monitoring.resultsStart,
                          monitoring.resultsEnd
                        )
                      }
                    >
                      Analyze
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {filteredMonitorings && (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            rowsPerPage={rowsPerPage}
            component="div"
            count={filteredMonitorings.length}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Container>
    </Paper>
  );
};
