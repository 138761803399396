import { Checkbox, Menu, MenuItem, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from '../../theme';

type Props = {
  projects: string[];
  handleToggleProjects: (project: string) => void;
  checkedProjects: string[];
};

export const ProjectFilter: React.FC<Props> = ({ projects, handleToggleProjects, checkedProjects }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isAllSelected = projects.length > 0 && checkedProjects.length === projects.length;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const classes = useStyles();
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Button className={classes.primaryButton} onClick={handleOpenMenu}>
        Filter by project
      </Button>
      <Menu
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem key="All" onClick={() => handleToggleProjects('All')}>
          <Checkbox edge="start" checked={isAllSelected} disableRipple inputProps={{ 'aria-labelledby': 'All' }} />
          All
        </MenuItem>
        {projects.map(value => {
          return (
            <MenuItem key={value} onClick={() => handleToggleProjects(value)}>
              <Checkbox
                data-testid={`checkbox ${value}`}
                edge="start"
                checked={checkedProjects.indexOf(value) !== -1}
                disableRipple
              />
              {value}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
