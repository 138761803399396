import Monitoring from '../domain/Monitoring';
import { Dispatcher } from '../../Core/Dispatcher';

class MonitoringsRepository {
  private readonly dispatcher: Dispatcher;

  constructor() {
    this.dispatcher = new Dispatcher();
  }

  async retrieve(platform: string, token: string): Promise<Monitoring[]> {
    const monitoringsResponse = await this.dispatcher.dispatchQuery({
      name: `${platform}-monitorings`,
      token
    });
    return monitoringsResponse.data.monitorings;
  }
}

const monitoringsRepository = new MonitoringsRepository();

export { monitoringsRepository };
