const paths = {
  HOME: '/',
  REPORTS: '/reports',
  MONITORINGS: '/monitorings',
  TALKWALKER_MONITORINGS: '/talkwalker-monitorings',
  BRANDWATCH_MONITORINGS: '/brandwatch-monitorings',
  BRANDWATCH_QUERIES: '/brandwatch-queries',
  NETBASE_MONITORINGS: '/netbase-monitorings',
  HUBSPOT: '/hubspot'
};

export default paths;
