import { Button, Container, Grid, Paper, TablePagination, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Title from '../../shared/Title';
import { useStyles } from '../../theme';
import { Query } from '../domain/Query';
import { SearchBar } from '../../Monitorings/presentation/SearchBar';

type Props = {
  queries: Query[];
  handleOpenAnalyze: (id: string, name: string, groupId: string, start: string, end: string) => void;
};
export const BrandwatchQueriesList: React.FunctionComponent<Props> = ({ queries, handleOpenAnalyze }) => {
  function createHandleAnalyze(id: string, name: string, groupId: string, start: string, end: string) {
    handleOpenAnalyze(id, name, groupId, start, end);
  }

  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [checkedProjects, setCheckedProjects] = React.useState<string[]>([]);

  const classes = useStyles();

  const filterQueries = (queries: Query[]): Query[] => {
    let projectQueries = queries.filter(query => checkedProjects.includes(query.name));
    let newQueries = projectQueries.filter((query: Query) => {
      return query.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    });
    return newQueries;
  };

  const filteredQueries = queries && filterQueries(queries);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const allProjects = queries && queries.map(query => query.name);
    const projects = allProjects && allProjects.filter((item, i, ar) => ar.indexOf(item) === i);
    if (projects) {
      setCheckedProjects(projects);
    }
  }, [queries]);

  return (
    <Paper className={classes.paper}>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Title>Queries</Title>
          </Grid>
          <Grid container item md={8} xs={12} alignItems="center">
            <SearchBar onChange={(value: string) => setSearchValue(value)} />
          </Grid>
        </Grid>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredQueries.length ? (
              <TableRow>
                <TableCell colSpan={5} align={'center'}>
                  There are no queries.
                </TableCell>
              </TableRow>
            ) : (
              filteredQueries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(query => (
                <TableRow key={query.id}>
                  <TableCell style={{ width: '50%' }}>
                    <Grid container alignItems="center">
                      {query.name}
                    </Grid>
                  </TableCell>

                  <TableCell style={{ alignContent: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      {query.type}
                      {query.hasTwitterContent && (
                        <Tooltip title="Twitter content available" aria-label="add">
                          <TwitterIcon />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      disabled={!query.hasTwitterContent}
                      onClick={() =>
                        createHandleAnalyze(
                          query.id.toString(),
                          query.name,
                          query.projectId.toString(),
                          query.creationDate,
                          query.lastModificationDate
                        )
                      }
                    >
                      {query.hasTwitterContent ? 'Analyze' : 'No Twitter Data'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {filteredQueries && (
          <TablePagination
            rowsPerPageOptions={[20, 40, 50]}
            rowsPerPage={rowsPerPage}
            component="div"
            count={filteredQueries.length}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Container>
    </Paper>
  );
};
