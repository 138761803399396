import { Button, Dialog, Grid, Typography, TextField, makeStyles } from '@material-ui/core';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { theme } from '../../../theme';

type Props = {
  onClose: () => void;
  open: boolean;
  handleCreateReport: (listId: string) => void;
  isLoading: boolean;
};

const useStyles = makeStyles({
  root: {
    padding: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(2)
  },
  containerItem: {
    margin: 6
  },
  primaryButtonForm: {
    fontFamily: 'Poppins',
    backgroundColor: '#ec674c',
    color: 'white',
    '&:hover': {
      backgroundColor: '#eb826c'
    }
  },
  secondaryButtonForm: {
    fontFamily: 'Poppins'
  }
});

type Inputs = {
  listId: string;
};

export const HubspotForm: React.FunctionComponent<Props> = ({ onClose, open, handleCreateReport, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const classes = useStyles();

  const onSubmit: SubmitHandler<Inputs> = data => handleCreateReport(data.listId);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.root}>
          <Grid item container justifyContent="center">
            <Typography variant="h6" className={classes.containerItem}>
              Export your Hubspot report
            </Typography>
          </Grid>

          <Typography color="textSecondary" className={classes.containerItem}>
            List ID
          </Typography>
          <Grid container item className={classes.containerItem}>
            <TextField
              {...register('listId', { required: 'List ID is required' })}
              id="listId"
              placeholder="List Id"
              size="small"
              fullWidth
            />
            {errors.listId && <p>{errors.listId.message}</p>}
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.container}>
            <Button
              variant="outlined"
              className={[classes.containerItem, classes.secondaryButtonForm].join(' ')}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              className={[classes.containerItem, classes.primaryButtonForm].join(' ')}
              type="submit"
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
